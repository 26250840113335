body {
  line-height: 1.6;
  letter-spacing: .4px;
}
p {
  margin-bottom: 1.5em;
}
a:hover {
  cursor: pointer;
}
input[type="text"],  input[type=email], input[type=password] {
  border-radius: 3px;
  padding: .5em 1em;
  border: 1px solid #ccc;
  display: block;
  font-size: 16px;
  margin-bottom: 1em;
  font-weight: 600;
}
input[type="submit"]:hover {
  cursor: pointer;
}
button {
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 3px;
  padding: .5em 1em;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1em;
  box-shadow: 0 0 0 0.5px rgba(50,50,93,.17), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent!important;
}
button:hover {
  cursor: pointer;
  background-color: #fafafa;
}
.icon {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
.box-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  max-height: 30px;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: fixed;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  height: 50px;
}

.App-link {
  color: #61dafb;
}

.page-title {
  margin-top: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-height {
  height: 50px;
}

.container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}

@media (min-width: 600px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }  
}
@media (min-width: 760px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }  
}
.container-header {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  position: relative;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.container-mobile {
  padding-left: 25px;
  padding-right: 25px;
}

html.toggle-for-sidebar-has-been-clicked {
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}
/* html.toggle-for-sidebar-has-been-clicked .sidebar-is-left-column-on-desktop main {
  // margin-left: 300px;
} */